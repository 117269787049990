import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./web_response.css";
import "./mobile_response.css";

const Family_stem = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch videos from the backend
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <Navbar />
      <main className="main_container">
        {/* Home Section */}
        <div className="stem_section">
          <div className="stem_intro">
            <h3 className="home_text" style={{ color: "#c5057d", opacity: 1 }}>
              Family STEM Show
            </h3>
            <p>
              Representatives of our indigenous families come to learn and
              showcase what they have learnt. It's usually a fine blend of
              children and Parents and sometimes, we involve schools and their
              students.
            </p>
          </div>

          {/* Main Video */}
          <div className="stem_video">
            {loading ? (
              <p>Loading video...</p>
            ) : videos[1] && videos[1].url ? (
              <iframe
                width="560"
                height="315"
                src={videos[1].url}
                title="Family STEM Show Video"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video available</p>
            )}
          </div>
        </div>

        {/* Stem Series Section */}
        <div className="video-grid">
          {loading ? (
            <p>Loading videos...</p>
          ) : videos.length >= 5 ? (
            <>
              {/* Video 1 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[2].url}
                  title="STEM Video 1"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 2 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[3].url}
                  title="STEM Video 2"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 3 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[4].url}
                  title="STEM Video 3"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 4 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[5].url}
                  title="STEM Video 4"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 5 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[6].url}
                  title="STEM Video 5"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 6 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[7].url}
                  title="STEM Video 6"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 7 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[8].url}
                  title="STEM Video 7"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 8 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[9].url}
                  title="STEM Video 8"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 9 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[10].url}
                  title="STEM Video 9"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 10 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[11].url}
                  title="STEM Video 10"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 11 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[12].url}
                  title="STEM Video 11"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 12 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[13].url}
                  title="STEM Video 12"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 13 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[14].url}
                  title="STEM Video 13"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 14 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[15].url}
                  title="STEM Video 14"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 15 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[16].url}
                  title="STEM Video 15"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 16 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[17].url}
                  title="STEM Video 16"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 17 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[18].url}
                  title="STEM Video 17"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 18 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[19].url}
                  title="STEM Video 18"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 19 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[20].url}
                  title="STEM Video 19"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 20 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[21].url}
                  title="STEM Video 20"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </>
          ) : (
            <p>No videos available</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Family_stem;
