import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./web_response.css";
import "./mobile_response.css";

const Graduates_stem = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch videos from the backend
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <Navbar />
      <main className="main_container">
        {/* Home Section */}
        <div className="stem_section">
          <div className="stem_intro">
            <h3 className="home_text" style={{ color: "#c5057d", opacity: 1 }}>
              STEM Graduates
            </h3>
            <p>
              We showcase students and Graduates from our own tertiary
              institutions who have excelled in these fields and are willing to
              share their innovations as well as educate the general public.
            </p>
          </div>

          {/* Main Video */}
          <div className="stem_video">
            {loading ? (
              <p>Loading video...</p>
            ) : videos[42] && videos[42].url ? (
              <iframe
                width="560"
                height="315"
                src={videos[42].url}
                title="STEM Graduates Video"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video available</p>
            )}
          </div>
        </div>

        {/* Stem Series Section */}
        <div className="video-grid">
          {loading ? (
            <p>Loading videos...</p>
          ) : videos.length >= 13 ? (
            <>
              {/* Video 1 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[43].url}
                  title="STEM Graduates Video 1"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 2 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[44].url}
                  title="STEM Graduates Video 2"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 3 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[45].url}
                  title="STEM Graduates Video 3"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 4 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[46].url}
                  title="STEM Graduates Video 4"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 5*/}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[47].url}
                  title="STEM Graduates Video 5"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 6 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[48].url}
                  title="STEM Graduates Video 6"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 7 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[49].url}
                  title="STEM Graduates Video 7"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 8 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[50].url}
                  title="STEM Graduates Video 8"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 9 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[51].url}
                  title="STEM Graduates Video 9"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 10 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[52].url}
                  title="STEM Graduates Video 10"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 11 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[53].url}
                  title="STEM Graduates Video 11"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 12 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[54].url}
                  title="STEM Graduates Video 12"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 13 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[55].url}
                  title="STEM Graduates Video 13"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 14 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[56].url}
                  title="STEM Graduates Video 14"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 15 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[57].url}
                  title="STEM Graduates Video 15"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 16 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[58].url}
                  title="STEM Graduates Video 16"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 17 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[59].url}
                  title="STEM Graduates Video 17"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 18 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[60].url}
                  title="STEM Graduates Video 18"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 19 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[61].url}
                  title="STEM Graduates Video 19"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 20 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[62].url}
                  title="STEM Graduates Video 20"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </>
          ) : (
            <p>No videos available</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Graduates_stem;
