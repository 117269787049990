import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./web_response.css";
import "./mobile_response.css";

const Women_stem = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch videos from the backend
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/videos`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div>
      <Navbar />
      <main className="main_container">
        {/* Home Section */}
        <div className="stem_section">
          <div className="stem_intro">
            <h3 className="home_text" style={{ color: "#c5057d", opacity: 1 }}>
              Women In STEM
            </h3>
            <p>
              We showcase women who against all odds have thrived in STEM
              fields. We believe that role modeling is a proven strategy to
              recruit more girls and women in STEM.
            </p>
          </div>

          {/* Main Video */}
          <div className="stem_video">
            {loading ? (
              <p>Loading video...</p>
            ) : videos[22] && videos[22].url ? (
              <iframe
                width="560"
                height="315"
                src={videos[22].url}
                title="Women in STEM Video"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video available</p>
            )}
          </div>
        </div>

        {/* Stem Series Section */}
        <div className="video-grid">
          {loading ? (
            <p>Loading videos...</p>
          ) : videos.length >= 9 ? (
            <>
              {/* Video 1 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[23].url}
                  title="Women in STEM Video 1"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 2 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[24].url}
                  title="Women in STEM Video 2"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>

              {/* Video 3 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[25].url}
                  title="Women in STEM Video 3"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 4 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[26].url}
                  title="Women in STEM Video 4"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 5 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[27].url}
                  title="Women in STEM Video 5"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 6 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[28].url}
                  title="Women in STEM Video 6"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 7 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[29].url}
                  title="Women in STEM Video 7"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 8 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[30].url}
                  title="Women in STEM Video 8"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 9 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[31].url}
                  title="Women in STEM Video 10"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 11 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[32].url}
                  title="Women in STEM Video 11"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 12 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[33].url}
                  title="Women in STEM Video 12"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 13 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[34].url}
                  title="Women in STEM Video 13"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 14 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[35].url}
                  title="Women in STEM Video 14"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 15 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[36].url}
                  title="Women in STEM Video 15"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 16 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[37].url}
                  title="Women in STEM Video 16"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 17 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[38].url}
                  title="Women in STEM Video 17"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 18 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[39].url}
                  title="Women in STEM Video 18"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 19*/}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[40].url}
                  title="Women in STEM Video 19"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              {/* Video 20 */}
              <div className="video">
                <iframe
                  width="100%"
                  height="100%"
                  src={videos[41].url}
                  title="Women in STEM Video 20"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            </>
          ) : (
            <p>No videos available</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Women_stem;
